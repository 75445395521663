import React from "react";
import styled from "styled-components";
import { Colors } from "../theme/theme";

const Label = styled.span`
  margin-right: 10px;
  width: 30%;
  font-size: 1.6rem;

  @media (max-width: 425px) {
    width: 50%;
  }
`;
const ProgressBar = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
`;
const ProgressFill = styled.span`
  background-color: ${Colors.primary};
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Skill = styled.div`
  display: flex;
`;
const Timeline = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 2px solid ${Colors.secondary};
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const skillList = [
  { label: "HTML & CSS", level: "100%" },
  { label: "JavaScript", level: "100%" },
  { label: "React", level: "60%" },
  { label: "TypeScript", level: "60%" },
  { label: "Project Management", level: "50%" },
  { label: "Python", level: "30%" },
];

const Skills = () => {
  return (
    <React.Fragment>
      <Timeline>
        <div>1 Year</div>
        <div></div>
        <div>2 Years</div>
        <div></div>
        <div>3 Years</div>
        <div></div>
        <div>4 Years</div>
      </Timeline>
      {skillList.map(function (skill, i) {
        return (
          <Skill key={i}>
            <ProgressBar>
              <ProgressFill width={skill.level}>
                <Label>{skill.label}</Label>
              </ProgressFill>
            </ProgressBar>
          </Skill>
        );
      })}
    </React.Fragment>
  );
};

export default Skills;
