import React from "react";

import { Tag, TagContainer, Content, Preview, StyledShowcase } from "./style";
const Showcase = ({ children, src, link, tags = [] }) => {
  return (
    <StyledShowcase target={"_blank"} href={link}>
      <Preview src={src} />

      <Content>{children}</Content>
      <TagContainer>
        {tags.map(function (tag, i) {
          return <Tag key={i}>{tag}</Tag>;
        })}
      </TagContainer>
    </StyledShowcase>
  );
};

export default Showcase;
