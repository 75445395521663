import {keyframes} from "styled-components";

const spinRight = keyframes`
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`
const loadingRing = keyframes`
	0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
`

const slideTop = keyframes`
	0% {
	-webkit-transform: translateY(-1000px);
	transform: translateY(-1000px);
	opacity: 0;
}
	100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
}
`



export {spinRight, loadingRing, slideTop}