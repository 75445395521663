import React from 'react';
import styled from "styled-components";
import Heading from "./basic/Heading";
import {Colors} from "../theme/theme";
import background from "../assets/img/backgrounds/MartiangreyNoPlanet.svg";
import RocketLaunch from "./animations/RocketLaunch";
import {Link} from "react-scroll";
import { useInView } from 'react-intersection-observer'
const Subtitle = styled.h3`
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 1rem;
`
const TextBox = styled.div`
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    
   

`
const StyledHero = styled.div`
  height: calc(100vh + 300px);
  display: flex;
  background-image: linear-gradient(
                  to right bottom,
                  rgba(${Colors.primaryDarkRGB}, 0.4),
                  rgba(${Colors.primaryDarkerRGB}, 0.4)),
  url(${background});
  background-attachment: fixed;
  background-position: center;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 1600px){
        height: calc(100vh + 400px);
  }
`
const Liftoff = styled.div`
  position: absolute;
  bottom: 1rem;
  right:3rem;
  width: 10px;
  text-transform: uppercase;
  z-index: 1000;
  height: 100vh;
  transition: all .3s;
  a {
    position: absolute;
    bottom: 1rem;
    right:1rem;
    writing-mode:vertical-lr;
    letter-spacing: .7rem;
    transition: letter-spacing .3s;
    text-orientation: upright;
    cursor: pointer;
  }
  
  a:hover {
    letter-spacing: 1rem;
    cursor: pointer;
  }
  
  span {
  font-size: 1.3rem;
  }
  
  @media (max-width: 425px){
    display: none;
  }
`

const Hero = () => {
    const [ref, inView] = useInView({
        threshold:.99
    })
    const scrollOffset = -85;
    return (
        <React.Fragment>

        <StyledHero>
            <TextBox>
                <Heading letterSpaced>Mandia Roux</Heading>
                <Subtitle>Frontend Developer</Subtitle>
            </TextBox>
        </StyledHero>
            <RocketLaunch/>
            <Liftoff ref={ref} >
                <Link activeClass="active" to="aboutSection" spy={true} smooth={"easeInOutQuart"} offset={scrollOffset} duration={3000} >
                    Lift off <span className="lnr lnr-arrow-down"/>
                </Link>
            </Liftoff>
        </React.Fragment>
    )
}

export default Hero