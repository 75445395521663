import styled from "styled-components";
import { Colors } from "../../theme/theme";

const StyledShowcase = styled.a`
  width: 100%;
  position: relative;
  margin: 1rem;
  padding: 3rem 1rem 3rem 1rem;
  border: 3px solid rgba(${Colors.primaryRGB}, 0.8);
  transition: all 0.5s;
  transform: translateY(0);
  color: ${Colors.white};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: rgba(${Colors.primaryRGB}, 0.8);
    cursor: pointer;
    box-shadow: 1px 8px 15px 2px ${Colors.primaryDarker};
    transform: translateY(-20px);
  }

  ul {
    text-align: center;
  }
  ul:last-child {
    padding-right: 10px;
  }

  @media (max-width: 1299px) {
    padding: 3rem 1rem 5rem 1rem;
  }

  @media (max-width: 425px) {
    margin: 1rem 0;
  }
`;

const Preview = styled.div`
  display: inline-block;
  background-image: url(${(props) => props.src});
  width: 80%;
  height: 7rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 3px;
  border: none;
`;

const TagContainer = styled.div`
  display: flex;
  align-content: space-between;
  font-size: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;
const Tag = styled.div`
  background-color: ${Colors.primary};
  color: ${Colors.white};

  margin: 3px;
  padding: 0.5rem;
  border-radius: 3px;
`;
const Content = styled.div`
  margin-bottom: 2rem;
  flex-grow: 2;
`;

export { Tag, TagContainer, Content, Preview, StyledShowcase };
