import React from 'react';
import styled from 'styled-components'
import Picture from "../assets/img/Me.jpeg";
import {Colors} from "../theme/theme";
import githubLogo from "../assets/icons/github.png"
import devLogo from "../assets/icons/dev.png"
import linkedInLogo from "../assets/icons/linkedin.png"


const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  
  @media (max-width: 425px){
  	  flex-direction: column;
  }
  
`

const Content = styled.div`
    text-align: left;
    p {
      max-width: 1000px;
      margin-top:1rem;
    }
    
    @media (max-width: 425px){
      width: 100%;
      text-align: center;
    }
`

const Image = styled.img`
  height: 22rem;
  width: 22rem;
  border-radius: 50%;
  border: solid 3px ${Colors.primary};
  float: left;
  shape-outside: circle(50%); 
  margin:0 4rem 1rem 2rem;
  
  @media (min-width: 1600px){
      height: 25rem;
      width: 25rem;
      transform: translateX(-2rem);
  }
  @media (max-width: 425px){
    float: none;
    margin: 2rem 4rem 3rem 4rem;
  }

`

const BadgeContainer = styled.div`
	text-align: right;
	width: 10%;
	margin-right: 4rem;
	display: flex;
	flex-direction: column;
	align-items:flex-end;
	
	@media (max-width:425px){
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-right: 0;
	}
	
`
const Badge = styled.a`
	display: inline-block;
	width: 3.5rem;
	img {
		width: 3.5rem;
		height: 3.5rem;
		transition: all .25s;
	}
	
	&:not(:last-child){
		margin-bottom: 1rem;
	}
	
	
		img {&:hover {
			transform: scale(1.4);
		}
	}
	
	@media (max-width: 425px){
		margin: 0 .5rem;
  	  &:not(:last-child){
		margin-bottom: 0;
		}
  	}
`

const Profile = () => {
	return (
		<Container>
			<BadgeContainer>
				<Badge href="https://dev.to/satraan" target={"_blank"} rel="noopener noreferrer">
					<img src={devLogo} alt="Mandia Roux's DEV Profile"/>
				</Badge>
				<Badge href="https://www.linkedin.com/in/mandia-roux-10088b156/" target={"_blank"}
					   rel="noopener noreferrer">
					<img src={linkedInLogo} alt="Mandia Roux's Linkedin Profile"/>
				</Badge>
				<Badge href="https://github.com/Satraan" target={"_blank"}
					   rel="noopener noreferrer">
					<img src={githubLogo} alt="Mandia Roux's Github Profile"/>
				</Badge>
			</BadgeContainer>
			<Content>
				
				<Image
					src={Picture}/>
				<p>I'm a full-time Frontend Engineer based in Cape Town, South Africa. <br/>
					I enjoy turning complex problems into simple, beautiful and intuitive
					designs. I have experience working in a dynamic startup, as well as in an agency where
					deadlines were critical.
					When I'm not coding, closing tickets or pushing pixels, you'll find me jamming
					video games,
					gardening or playing Magic: the Gathering.
				</p>
			</Content>
		
		</Container>
	
	)
}

export default Profile