import React from "react";
import styled from "styled-components";
import {Colors} from "../../theme/theme";
import {animateScroll as scroll, Link} from 'react-scroll';
import {useInView} from 'react-intersection-observer';

const StickyContainer = styled.div`
    top: 5rem;
    position: sticky;

    @media (max-width: 768px) {
    	z-index: 20;
      	top: 0;
      	height: 100vh;
    }

`

const Nav = styled.nav`
    position: absolute;
    right: 3rem;
    top: 0;
	
    display: flex;
    flex-direction: column;

    width: 95px;

    background-color: ${Colors.primaryDarker};
    border: 2px solid ${Colors.primary};
    color: ${Colors.primary};
    text-align: center;
    padding: 1rem;


    &.hide {
    	@media (max-width: 768px){
    		opacity: 0;
    	}
    }

	@media (max-width: 1300px){
		right: 1rem;
	}
	@media (max-width: 1024px){
		right: .5rem;
	}

    @media (max-width: 768px) {
		overflow: hidden;
		transition: all .3s;
		width: 100%;
		flex-direction: row;
		align-content: space-evenly;
		align-items: center;
		justify-content: space-evenly;
		
		left: 0;
		right: 0;
		height:75px;
    }
`
const Item = styled.div`
    display: block;
    height: 40px;
    font-size: 1.45rem;
    
    a, a:visited {
	    cursor: pointer;
    	color: ${Colors.primary};
    }
    
    &:not(:last-child){
      margin-bottom: 1rem;
    }
    
    a:active, a:focus {
    	color: ${Colors.primaryLight};
    	background-color: transparent;
    	user-select: none;
    }
    
    @media (min-width:769px) {
		&:hover {
			a {color: ${Colors.primaryLight};}
			i {
				color: ${Colors.primaryLight};
			}
		}
    }
  
    
	@media (max-width: 768px) {
		&:not(:last-child){
			margin-bottom: 0;
		}
		a {
			line-height: 4rem                       ;
		}
	}
    
`
const Icon = styled.i`
  font-size: 4rem;
  color:${Colors.primary};
  transform: rotate(45deg);
`
const StyledLink = styled.a`
	cursor: pointer;
`


const Navigation = () => {
	const [ref, inView] = useInView({
		threshold: .99
	})
	
	const scrollOffset = -85;
	
	return (
		<React.Fragment>
			<StickyContainer id={"sticky"} ref={ref}>
				
				<Nav className={inView ? "" : "hide"}>
					<Item>
						<StyledLink onClick={() => {
							scroll.scrollToTop()
						}}>
							<Icon className="icon-rocket-ship"/>
						</StyledLink>
					
					</Item>
					<Item>
						<Link activeClass="active" to="aboutSection" spy={true} smooth={true} offset={scrollOffset}
							  duration={500}>
							About
						</Link>
					</Item>
					<Item>
						<Link activeClass="active" to="skillsSection" spy={true} smooth={true} offset={scrollOffset}
							  duration={500}>
							Skills
						</Link>
					</Item>
					<Item>
						<Link activeClass="active" to="showcaseSection" spy={true} smooth={true} offset={scrollOffset}
							  duration={500}>
							Showcase
						</Link>
					</Item>
					<Item>
						<Link activeClass="active" to="contactSection" spy={true} smooth={true} offset={scrollOffset}
							  duration={500}>
							Contact
						</Link>
					</Item>
				
				</Nav>
			
			</StickyContainer>
		</React.Fragment>
	)
};

export default Navigation